
import XTable from "@/components/x-table/x-table.vue";
import {Component, Vue} from "vue-property-decorator";
import {$xTable, XTableProxy, XTableStdRequestParams, XTableStdResponse} from "@/components/x-table/table.proxy";
import * as API_Goods from '@/api/goods'
import {Foundation} from '@/../ui-utils';

@Component({
  name: "PriceChangeRecord",
  components: {
    XTable,
  }
})
export default class PriceChangeRecord extends Vue {
  tableProxy: XTableProxy = null!;

  mounted() {
    this.tableProxy = $xTable.create({
      key: 'sn',
      search: {
        formData() {
          return {
            time_range: '',
            status: ''
          }
        }
      },
      onRequest: (params: XTableStdRequestParams): Promise<XTableStdResponse> => {
        return new Promise(resolve => {
          if (params.search.time_range) {
            params.search["opt_time_start"] = params.search.time_range[0] / 1000
            params.search["opt_time_end"] = params.search.time_range[1] / 1000 + 86399
          }
          API_Goods.goodsChangePrice({
            page_no: params.pageIndex,
            page_size: params.pageSize,
            ...params.search
          }).then(res => {
            res.data.forEach(item => {
              item.type = item.type === 0 ? '批量改价' : '单品改价'
              item.status = item.status === 0 ? '进行中' : '已完成'
              item.opt_time = Foundation.unixToDate(item.opt_time, '')
            })
            resolve(res)
          })
        });
      }
    });
    console.log(this.tableProxy)
  }
}
